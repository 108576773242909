export const TuxERC20 = [
  "event Approval(address indexed owner, address indexed spender, uint256 value)",
  "event Transfer(address indexed from, address indexed to, uint256 value)",
  "function addPayoutAddress(address payoutAddress)",
  "function allowance(address owner, address spender) view returns (uint256)",
  "function approve(address spender, uint256 amount) returns (bool)",
  "function balanceOf(address account) view returns (uint256)",
  "function burn(uint256 amount)",
  "function burnFrom(address account, uint256 amount)",
  "function cancel(uint256 auctionId, address from)",
  "function decimals() view returns (uint8)",
  "function decreaseAllowance(address spender, uint256 subtractedValue) returns (bool)",
  "function feature(uint256 auctionId, uint256 amount, address from)",
  "function featured() view returns (uint256)",
  "function featuredDuration() view returns (uint256)",
  "function getFeatured(uint256 from, uint256 n) view returns (uint256[])",
  "function getFeaturedContains(uint256 auctionId) view returns (bool)",
  "function getFeaturedLength() view returns (uint256 length)",
  "function getFeaturedPrice(uint256 auctionId) view returns (uint256 price)",
  "function getNextFeaturedTime() view returns (uint256 timestamp)",
  "function increaseAllowance(address spender, uint256 addedValue) returns (bool)",
  "function mint(address to, uint256 amount)",
  "function minter() view returns (address)",
  "function name() view returns (string)",
  "function nextFeaturedTime() view returns (uint256)",
  "function nextPayoutsTime() view returns (uint256)",
  "function owner() view returns (address)",
  "function payoutAmount() view returns (uint256)",
  "function payouts()",
  "function payoutsFrequency() view returns (uint256)",
  "function removePayoutAddress(address payoutAddress)",
  "function renounceOwnership()",
  "function setMinter(address minter_)",
  "function symbol() view returns (string)",
  "function totalSupply() view returns (uint256)",
  "function transfer(address recipient, uint256 amount) returns (bool)",
  "function transferFrom(address sender, address recipient, uint256 amount) returns (bool)",
  "function updateFeatured()",
  "function updatePayoutAmount(uint256 amount)"
]
